@font-face {
  font-family: 'LatoRegular';
  font-style: normal;
  font-weight: 400;
  src: local('LatoRegular'), local('LatoRegular'),
       url('../Fonts/Lato-Regular.ttf') format('truetype'); /* Legacy iOS */
}

@font-face {
  font-family: 'LatoBold';
  font-style: normal;
  font-weight: 700;
  src: local('LatoBold'), local('LatoBold'),
       url('../Fonts/Lato-Bold.ttf') format('truetype'); /* Legacy iOS */
}

@font-face {
  font-family: 'LatoLight';
  font-style: normal;
  font-weight: 300;
  src: local('LatoLight'), local('LatoLight'),
       url('../Fonts/Lato-Light.ttf') format('truetype'); /* Legacy iOS */
}
/* total width */
body::-webkit-scrollbar {
  background-color: transparent;
  width: 16px;
  height: 16px;
}
/* background of the scrollbar except button or resizer */
body::-webkit-scrollbar-track {
  background-color: transparent;
}
/* scrollbar itself */
body::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}
/* set button(top and bottom of the scrollbar) */
body::-webkit-scrollbar-button {
  display:none;
}